import PropTypes from 'prop-types';

import Link from '@mui/material/Link';
import Masonry from '@mui/lab/Masonry';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { alpha, styled, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Button, { buttonClasses } from '@mui/material/Button';

import Logo from 'src/components/logo_black';
import Iconify from 'src/components/iconify';
import { usePathname } from 'src/routes/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { RouterLink } from 'src/routes/components';
import { useResponsive } from 'src/hooks/use-responsive';

import { pageLinks, navConfig } from './config-navigation';


export default function Footer() {
  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const pathname = usePathname();

  const mobileList = navConfig.find((i) => i.title === 'Pages')?.children || [];

  const desktopList = pageLinks.sort((listA, listB) => Number(listA.order) - Number(listB.order));

  const renderLists = mdUp ? desktopList : mobileList;
  

  const _socials = [
    {
      value: 'xing',
      label: 'Xing',
      icon: 'carbon:logo-xing',
      color: '#007EBB',
      link: 'https://www.xing.com/profile/Alper_Koyuncu/',
    },
    {
      value: 'github',
      label: 'GitHub',
      icon: 'carbon:logo-github',
      color: '#1877F2',
      link: 'https://github.com/alprkync',
    },
    {
      value: 'instagram',
      label: 'Instagram',
      icon: 'carbon:logo-instagram',
      color: '#E02D69',
      link: 'https://www.instagram.com/zebra360.de/',
    },
    {
      value: 'x',
      label: 'X',
      icon: 'carbon:logo-x',
      color: '#00AAEC',
      link: 'https://x.com/alperkoyuncu',
    },
  ];

  const mainFooter = (
    <>
      <Divider />

      <Container
  sx={{
    overflow: 'hidden',
    pt: { xs: 8, md: 10 },
    pb: { xs: 8, md: 5 },
  }}
>
  <Grid container spacing={3} justifyContent={{ md: 'space-between' }}>
    <Grid xs={12} md={4}>
      <Stack spacing={{ xs: 3, md: 5 }} justifyContent="center" alignItems="center">
        <Stack alignItems={{ xs: 'center', md: 'flex-start' }} spacing={3}>
          <Logo />
          <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: { xs: 'center', md: 'left' } }}>
            Zebra360 Digitalagentur <br />
            Alper Koyuncu <br />
            Konrad-Adenauer-Str. 4<br />
            93077 Bad Abbach<br />
            Tel.: +49 (0)9405 955 13 43<br />
            Email: Info@zebra360.de
          </Typography>
        </Stack>
        <Stack spacing={2}>
        <Stack direction="row" alignItems="center">
          {_socials.map((social) => (
            <IconButton 
              key={social.value} 
              color={theme.palette.grey[500]}
              component="a"
              href={social.link}
              target="_blank" 
              rel="noopener noreferrer"
            >
              <Iconify icon={social.icon} />
            </IconButton>
          ))}
        </Stack>
        </Stack>
      </Stack>
    </Grid>
    <Grid xs={12} md={8}>
      {mdUp ? (
        <Masonry columns={4} spacing={1} defaultColumns={4} defaultSpacing={1}>
          {renderLists.map((list) => (
            <ListDesktop key={list.subheader} list={list} />
          ))}
        </Masonry>
      ) : (
        <Stack spacing={1.5}>
          {renderLists.map((list) => (
            <ListMobile key={list.subheader} list={list} />
          ))}
        </Stack>
      )}
    </Grid>
  </Grid>
</Container>


      <Divider />

      <Container>
        <Stack
          spacing={2.5}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{ py: 3, textAlign: 'center' }}
        >
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
             
          </Typography>

          <Stack direction="row" spacing={3} justifyContent="center">
            <Link variant="caption" href="/agentur/impressum" sx={{ color: 'text.secondary' }}>
              Impressum
            </Link>

            <Link variant="caption" href="/agentur/datenschutz" sx={{ color: 'text.secondary' }}>
              Datenschutzerklärung
            </Link>
          </Stack>
        </Stack>
      </Container>
    </>
  );

  return <footer>{mainFooter}</footer>;
}

// ----------------------------------------------------------------------

export function ListDesktop({ list }) {
  const pathname = usePathname();

  return (
    <Stack spacing={1.5} alignItems="flex-start">
      <Typography variant="subtitle2">{list.subheader}</Typography>

      {list.items?.map((link) => {
        const active = pathname === link.path || pathname === `${link.path}/`;

        return (
          <Link
            component={RouterLink}
            key={link.title}
            href={link.path}
            variant="caption"
            sx={{
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
              },
              ...(active && {
                color: 'text.primary',
                fontWeight: 'fontWeightSemiBold',
              }),
            }}
          >
            {link.title}
          </Link>
        );
      })}
    </Stack>
  );
}

ListDesktop.propTypes = {
  list: PropTypes.shape({
    items: PropTypes.array,
    subheader: PropTypes.string,
  }),
};

// ----------------------------------------------------------------------

export function ListMobile({ list }) {
  const pathname = usePathname();

  const listExpand = useBoolean();

  return (
    <Stack spacing={1.5} alignItems="center">
      <Typography
        variant="subtitle2"
        onClick={listExpand.onToggle}
        sx={{
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {list.subheader}
        <Iconify
          width={16}
          icon={listExpand.value ? 'carbon:chevron-down' : 'carbon:chevron-right'}
          sx={{ ml: 0.5 }}
        />
      </Typography>

      <Collapse in={listExpand.value} unmountOnExit sx={{ width: 1 }}>
        <Stack spacing={1.5} alignItems="center">
          {list.items?.map((link) => (
            <Link
              component={RouterLink}
              key={link.title}
              href={link.path}
              variant="caption"
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'text.primary',
                },
                ...(pathname === `${link.path}/` && {
                  color: 'text.primary',
                  fontWeight: 'fontWeightSemiBold',
                }),
              }}
            >
              {link.title}
            </Link>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}

ListMobile.propTypes = {
  list: PropTypes.shape({
    items: PropTypes.array,
    subheader: PropTypes.string,
  }),
};

// ----------------------------------------------------------------------


