import { useEffect } from 'react';
import PropTypes from 'prop-types';

import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

import { useResponsive } from 'src/hooks/use-responsive';
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import Logo from 'src/components/logo_black';
import Iconify from 'src/components/iconify';
import { usePathname } from 'src/routes/hooks';
import Scrollbar from 'src/components/scrollbar';
import { useBoolean } from 'src/hooks/use-boolean';
import { alpha, useTheme } from '@mui/material/styles';

import { NAV } from '../../../config-layout';

import NavList from './nav-list';

import { useLocation } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function NavMobile({ data }) {
  const pathname = usePathname();

  const theme = useTheme();

  const mobileOpen = useBoolean();

  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    if (mobileOpen.value) {
      mobileOpen.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const location = useLocation();
  const validPaths = ['/' , '/leistungen/branding' , '/leistungen/branding/' , '/leistungen/webdesign' , '/leistungen/webdesign/' , '/leistungen/grafikdesign' , '/leistungen/grafikdesign/', '/leistungen/ecommerce' , '/leistungen/ecommerce/' ,'/leistungen/webdesign/website-relaunch' , '/leistungen/webdesign/website-relaunch/', '/beratung/ki-strategie', '/beratung/ki-strategie/', '/beratung/digitalisierung', '/beratung/digitalisierung/', '/beratung/projekt-management', '/beratung/projekt-management/' ];
  const isAboutRoute = validPaths.includes(location.pathname);

  return (
    <>
      <IconButton onClick={mobileOpen.onTrue} sx={{ ml: 1, 
        
        color: offset ? alpha(theme.palette.grey[900], 0.7) : isAboutRoute ? 'common.white'  : alpha(theme.palette.grey[900], 0.7)
        
        }}>
      <Iconify icon="carbon:menu" sx={{ width: offset && !mdUp ? 24: 36, height: offset && !mdUp  ? 24: 36 }} />
      </IconButton>

      <Drawer
        open={mobileOpen.value}
        onClose={mobileOpen.onFalse}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_VERTICAL,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <List component="nav" disablePadding>
            {data.map((link) => (
              <NavList key={link.title} item={link} />
            ))}
          </List>

          <Stack spacing={1.5} sx={{ p: 3 }}>
            <Button fullWidth variant="contained" color="inherit" href="/agentur/kontakt"
            sx={{ 
             fontWeight: 400, 
              fontSize: '15px', 
              textTransform: 'unset',
            }}>
              Lass uns eine Safari planen!
            </Button>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}

NavMobile.propTypes = {
  data: PropTypes.array,
};
